<template>
  <Module ref="module">
    <div el="list sm">
      <div el="list-column-between">
        <!-- <h3 el v-if="module.label === 'Event Details'">
          {{ module.data.description }}
        </h3> -->
        <h3 el>{{ module.label }}</h3>
        <span el="icon" class="material-icons" @click="$refs.module.toggle()"
          >close</span
        >
      </div>
      <div el="list xxsm" v-if="module.label === 'Event Details'">
        <div>
          <span el="status-success" v-if="module.data.public"
            >Public event</span
          >
          <span el="status-pending" v-else>Private event</span>
        </div>
        <p el><b>Description:</b> {{ module.data.description }}</p>
        <p el><b>Starts:</b> {{ formatDate(module.data.start) }}</p>
        <p el><b>Ends:</b> {{ formatDate(module.data.end) }}</p>
        <hr el />
        <p el><b>Created on:</b> {{ formatDate(module.data.createdOn) }}</p>
        <!-- <p el><b>Status:</b> {{ module.data.status }}</p> -->
        <p el v-if="isAdmin"><b>Created by:</b> {{ module.data.name }}</p>
        <p el><b>Request type:</b> {{ module.data.type }}</p>
        <!-- <p el><b>Request ID:</b> {{ module.data.id }}</p> -->
        <!-- <p el><b>Approved by:</b> {{ module.data.approvedBy.name }}</p> -->
      </div>
      <div
        el="list xsm"
        v-if="module.label === 'New Event' || module.label === 'Edit Event'"
      >
        <Calendar ref="datePicker" :set="module.data.start" />
        <label
          >Start time:
          <input
            type="time"
            class="form-input"
            v-model="input.clubhouse.start"
          />
        </label>
        <label
          >End time:
          <input type="time" class="form-input" v-model="input.clubhouse.end" />
        </label>
        <label
          >Description (required)
          <input
            type="text"
            class="form-input"
            style="width: 100%"
            v-model="input.clubhouse.description"
          />
        </label>
        <div>
          <div
            el="toggle"
            :class="{ active: input.clubhouse.public }"
            @click="input.clubhouse.public = !input.clubhouse.public"
          >
            public
          </div>
        </div>
      </div>
      <div el="list-column" style="justify-content: end" v-if="isAdmin">
        <div
          el="btn-2ry"
          @click="deleteEvent(module.data.id)"
          v-if="module.label === 'Event Details'"
        >
          delete
        </div>
        <div
          el="btn"
          @click="editEvent(module.data)"
          v-if="module.label === 'Event Details'"
        >
          edit
        </div>
        <div el="btn" @click="submitEvent" v-if="module.label === 'New Event'">
          Create Event
        </div>
        <div
          el="btn"
          @click="updateEvent(module.data)"
          v-if="module.label === 'Edit Event'"
        >
          Update
        </div>
      </div>
    </div>
  </Module>
  <div class="cal">
    <div class="controls">
      <span
        :class="{
          'material-icons': true,
          navigation: true,
          // inactive: date <= today,
        }"
        @click="prev"
        >navigate_before</span
      >
      <div class="title" @click="findToday">
        {{ month }} {{ date.getFullYear() }}
      </div>
      <span class="material-icons navigation" @click="next">navigate_next</span>
    </div>
    <ol class="weekdays">
      <li>Su</li>
      <li>Mo</li>
      <li>Tu</li>
      <li>We</li>
      <li>Th</li>
      <li>Fi</li>
      <li>Sa</li>
    </ol>
    <ol class="days" :style="{ '--col-start': firstWeekday + 1 }">
      <!-- <li :class="{'first-day':true, today: currentDay == 1, inactive: 1 < currentDay && month == currentMonth && date.getFullYear() == currentYear, selected: selected == 1}" :style="{'grid-column-start':firstWeekday+1}" @click="selectDay(1)">1</li> -->
      <li
        v-for="(item, index) in dayArr"
        :key="item"
        :class="{
          'first-day': index == 0,
          today: compareDates(item, today),
          // inactive: item < today,
          selected: compareDates(item, selected),
        }"
        @click="selectDay(item)"
      >
        {{ item.getDate() }}
        <!-- <div v-for="event in events" :key="event" class="event">
          <span>{{ event.description }}</span>
        </div> -->
        <div
          class="event"
          v-if="findEvent(item) != null && isAdmin"
          @click.stop="viewEvent(findEvent(item))"
        >
          <span :title="findEvent(item).description">{{
            findEvent(item).description
          }}</span>
        </div>
        <div
          class="event"
          v-if="findEvent(item) != null && !isAdmin"
          @click.stop="viewEvent(findEvent(item))"
        >
          <span :title="findEvent(item).description">{{
            findEvent(item).public
              ? findEvent(item).description
              : "Private Event"
          }}</span>
        </div>
      </li>
    </ol>
  </div>
</template>

<script>
// @ is an alias to /src
// import HelloWorld from '@/components/HelloWorld.vue'
// import {auth,db} from '../firebase'
// import gsap from "gsap";
// import {ref} from 'vue'
import { auth, db } from "../firebase";
import Module from "@/components/Module.vue";
import Calendar from "@/components/Calendar.vue";
import { reactive } from "vue";
import _ from "lodash";

export default {
  name: "CalendarMod",
  props: ["set"],
  components: {
    // HelloWorld
    Module,
    Calendar,
  },
  data() {
    return {
      today: new Date(),
      date: new Date(),
      dayArr: [],
      mS: [
        "Jan",
        "Feb",
        "Mar",
        "Apr",
        "May",
        "June",
        "July",
        "Aug",
        "Sept",
        "Oct",
        "Nov",
        "Dec",
      ],
      selected: null,
      // readOnly: false,
      module: {
        label: "Request Details",
        data: reactive({}),
      },
      input: {
        clubhouse: {
          description: "",
          start: "",
          end: "",
          public: false,
        },
      },
      events: reactive({}),
    };
  },
  methods: {
    generateCal() {
      this.dayArr = [];
      for (let i = 0; i < this.lastDay; i++) {
        let d = new Date(this.date.getFullYear(), this.date.getMonth(), i + 1);
        this.dayArr.push(d);
      }
    },
    selectDay(item) {
      if (item >= this.today) {
        this.selected = item;
        // this.module.data = item;
        if (this.isAdmin) {
          this.module.label = "New Event";
          this.module.data.start = item;

          this.module.data.end = "";
          this.module.data.description = "";
          this.module.data.public = false;
          this.$refs.module.toggle();
        }
      }
    },
    findToday() {
      this.date = new Date(this.today);
    },
    next() {
      this.date.setMonth(this.date.getMonth() + 1);
      this.date = new Date(this.date);
    },
    prev() {
      // if (this.date > this.today) {
      this.date.setMonth(this.date.getMonth() - 1);
      this.date = new Date(this.date);
      // }
    },
    compareDates(d1, d2) {
      return d1.getTime() == d2.getTime();
    },
    setDate(val) {
      let date = new Date(val);
      let d = new Date(date.getFullYear(), date.getMonth(), date.getDate());
      this.date.setMonth(date.getMonth());
      this.date = new Date(this.date);
      this.selectDay(d);
    },
    findEvent(date) {
      let x = this;
      return _.find(this.events, function (item) {
        let start = new Date(item.start);
        return x.sameDay(start, date);
      });
    },
    viewEvent(item) {
      this.module.data = _.clone(item);
      this.module.label = "Event Details";
      if (item.public || this.isAdmin) {
        this.$refs.module.toggle();
      }
    },
    submitEvent() {
      var x = this;
      let d = new Date(x.$refs.datePicker.selected);
      let ref = x.input.clubhouse;
      let s = ref.start.split(":"),
        e = ref.end.split(":");
      d.setHours(s[0], s[1], 0);
      let start = d.getTime();
      d.setHours(e[0], e[1], 0);
      let end = d.getTime();

      if (ref.description == "") {
        alert("You must fill in details correctly");
        return;
      }

      db.collection("events")
        .add({
          type: "clubhouse reservation",
          createdOn: Date.now(),
          start: start,
          end: end,
          description: ref.description,
          public: ref.public,
          uid: auth.currentUser.uid,
          name: x.userProfile.firstName + " " + x.userProfile.lastName,
          status: "complete",
          // approvedBy: {
          //   uid: null,
          //   name: "",
          // },
        })
        .then(() => {
          //logout
          ref.description = "";
          ref.start = "";
          ref.end = "";
          console.log("added request!");
          x.getRequestData();
        })
        .catch((error) => {
          alert(error);
          console.error("Error removing document: ", error);
        });
      this.$refs.module.toggle();
    },
    deleteEvent(id) {
      var x = this;
      this.$refs.module.toggle();
      db.collection("events")
        .doc(id)
        .delete()
        .then(() => {
          // x.getRequestData();
          delete x.events[id];
        })
        .catch((error) => {
          alert(error);
          console.error("Error removing document: ", error);
        });
    },
    editEvent(item) {
      // this.module.data = item;
      this.module.label = "Edit Event";
      // this.$refs.module.toggle();
      this.input.clubhouse.start = this.getTimeFromDate(item.start);
      this.input.clubhouse.end = this.getTimeFromDate(item.end);
      this.input.clubhouse.description = item.description;
      this.input.clubhouse.public = item.public;
    },
    updateEvent(item) {
      var x = this;
      let d = new Date(x.$refs.datePicker.selected);
      let ref = x.input.clubhouse;
      let s = ref.start.split(":"),
        e = ref.end.split(":");
      d.setHours(s[0], s[1], 0);
      let start = d.getTime();
      d.setHours(e[0], e[1], 0);
      let end = d.getTime();

      if (ref.description == "") {
        alert("You must fill in details correctly");
        return;
      }

      db.collection("events")
        .doc(item.id)
        .update({
          start: start,
          end: end,
          description: ref.description,
          public: ref.public,
        })
        .then(() => {
          //logout
          ref.description = "";
          ref.start = "";
          ref.end = "";
          x.$refs.module.toggle();
          x.getRequestData();
          // x.events[item.id].start = start;
          // x.events[item.id].end = end;
          // x.events[item.id].description = ref.description;
        })
        .catch((error) => {
          alert(error);
          console.error("Error updating document: ", error);
        });
    },
    getTimeFromDate(date) {
      let d = new Date(date),
        hrs = d.getHours() < 10 ? "0" + d.getHours() : d.getHours(),
        mins = d.getMinutes() < 10 ? "0" + d.getMinutes() : d.getMinutes();
      return hrs + ":" + mins;
    },
    getRequestData() {
      var x = this;
      var events = db.collection("events");
      // x.requests.list.splice(0, x.requests.list.length);
      x.events = reactive({});
      events
        .get()
        .then((querySnapshot) => {
          querySnapshot.forEach((doc) => {
            var data = doc.data();
            data.id = doc.id;
            x.events[doc.id] = data;
          });
        })
        .catch((error) => {
          console.log("Error getting documents: ", error);
        });
    },
    // convertRequestData() {
    //   var x = this;
    //   var requests = db.collection("requests");
    //   requests
    //     .get()
    //     .then((querySnapshot) => {
    //       querySnapshot.forEach((doc) => {
    //         var data = doc.data();
    //         data.id = doc.id;

    //         db.collection("events")
    //           .add({
    //             type: "clubhouse reservation",
    //             createdOn: data.createdOn,
    //             start: data.start,
    //             end: data.end,
    //             description: data.description,
    //             public: true,
    //             uid: auth.currentUser.uid,
    //             name: x.userProfile.firstName + " " + x.userProfile.lastName,
    //             status: "complete",
    //           })
    //           .then(() => {
    //             console.log("added event!");
    //             x.getRequestData();
    //           })
    //           .catch((error) => {
    //             alert(error);
    //             console.error("Error removing document: ", error);
    //           });
    //       });
    //     })
    //     .catch((error) => {
    //       console.log("Error getting documents: ", error);
    //     });
    // },
    sameDay(d1, d2) {
      return (
        d1.getFullYear() === d2.getFullYear() &&
        d1.getMonth() === d2.getMonth() &&
        d1.getDate() === d2.getDate()
      );
    },
    formatDate(date) {
      let d = new Date(date);
      return d.toLocaleString("en-US");
    },
  },
  watch: {
    date() {
      this.generateCal();
    },
  },
  computed: {
    userProfile() {
      return this.$store.state.userProfile;
    },
    isAdmin() {
      return _.includes(this.userProfile.permissions, "admin");
    },
    month() {
      return this.mS[this.date.getMonth()];
    },
    firstWeekday() {
      return new Date(
        this.date.getFullYear(),
        this.date.getMonth(),
        1
      ).getDay();
    },
    lastDay() {
      return new Date(
        this.date.getFullYear(),
        this.date.getMonth() + 1,
        0
      ).getDate();
    },
    currentMonth() {
      return this.mS[this.today.getMonth()];
    },
  },
  mounted() {
    this.today = new Date(
      this.today.getFullYear(),
      this.today.getMonth(),
      this.today.getDate()
    );
    this.date = new Date(this.date.getFullYear(), this.date.getMonth(), 1);
    this.selected = this.today;
    this.generateCal();
    if (this.set != null) {
      this.setDate(this.set);
    }
    this.getRequestData();
  },
};
</script>
<style lang="scss" scoped>
.cal {
  display: grid;
  width: 100%;
  color: var(--color-ocean);
  user-select: none;
  grid-template-rows: max-content max-content 1fr;
  height: 480px;
}
.cal .title {
  text-transform: uppercase;
}
.cal .controls {
  display: grid;
  justify-content: space-between;
  align-items: center;
  grid-auto-flow: column;
}
.cal .navigation {
  padding: 8px;
  border-radius: 3px;
  transition: 0.3s;
}
.cal .navigation:hover {
  color: black;
  background-color: lightblue;
  cursor: pointer;
}
.cal .navigation.inactive {
  color: hsla(0, 0%, 0%, 0.25);
  cursor: not-allowed;
  background: transparent !important;
}
.cal ol {
  list-style: none;
  margin: 0;
  padding: 0;
  text-align: center;
  display: grid;
  grid-template-columns: repeat(7, 1fr);
  grid-auto-rows: 1fr;
}
.cal .weekdays {
  height: 45px;
}
.cal ol li {
  // height: 45px;
  display: flex;
  // align-items: center;
  justify-content: center;
  cursor: pointer;
  transition: 0.3s;
  padding: 8px;
}
.cal .days {
  --col-start: 1;
  grid-gap: 1px;
  background-color: #f1f1f1;
  border: 1px solid #f1f1f1;
  color: var(--color-65);
}
.cal .days li:first-child {
  grid-column-start: var(--col-start);
}
.cal .days li {
  display: grid;
  outline: 1px solid transparent;
  background-color: #fff;
  align-content: start;
  justify-content: stretch;
  grid-gap: 2px;
}
.cal .days li .event {
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
  padding: 0 4px;
  border-radius: 3px;
  background-color: var(--color-aurora);
  color: #fff;
  width: 100%;
  transition: background-color 0.2s;
}
.cal .days li .event:hover {
  background-color: var(--color-earth);
}
.cal .days li .event * {
  font-size: 11px;
}
.cal .days li:not(.inactive):hover {
  outline: 1px solid var(--color-ocean);
}
.cal .days .selected {
  background-color: var(--color-ocean);
  outline: 1px solid #fff;
  outline-offset: -6px;
  color: #fff;
}
.cal .days .today {
  // background-color:#7AB2DC;
  outline: 1px solid var(--color-mist);
}
.cal .days .inactive {
  background-color: hsla(0, 0%, 0%, 0.25);
  color: hsla(0, 0%, 100%, 0.65);
  cursor: not-allowed;
}
.first-day {
  grid-column-start: 2;
}
</style>
