<template>
  <div class="datePicker">
    <div class="controls">
      <span
        :class="{
          'material-icons': true,
          navigation: true,
          inactive: date <= today,
        }"
        @click="prev"
        >navigate_before</span
      >
      <div class="title" @click="findToday">
        {{ month }} {{ date.getFullYear() }}
      </div>
      <span class="material-icons navigation" @click="next">navigate_next</span>
    </div>
    <ol class="weekdays">
      <li>Su</li>
      <li>Mo</li>
      <li>Tu</li>
      <li>We</li>
      <li>Th</li>
      <li>Fi</li>
      <li>Sa</li>
    </ol>
    <ol class="days" :style="{ '--col-start': firstWeekday + 1 }">
      <!-- <li :class="{'first-day':true, today: currentDay == 1, inactive: 1 < currentDay && month == currentMonth && date.getFullYear() == currentYear, selected: selected == 1}" :style="{'grid-column-start':firstWeekday+1}" @click="selectDay(1)">1</li> -->
      <li
        v-for="(item, index) in dayArr"
        :key="item"
        :class="{
          'first-day': index == 0,
          today: compareDates(item, today),
          inactive: item < today,
          selected: compareDates(item, selected),
        }"
        @click="selectDay(item)"
      >
        {{ item.getDate() }}
      </li>
    </ol>
  </div>
</template>

<script>
// @ is an alias to /src
// import HelloWorld from '@/components/HelloWorld.vue'
// import {auth,db} from '../firebase'
// import gsap from "gsap";
// import {ref} from 'vue'

export default {
  name: "Calendar",
  props: ["set"],
  components: {
    // HelloWorld
  },
  data() {
    return {
      today: new Date(),
      date: new Date(),
      dayArr: [],
      mS: [
        "Jan",
        "Feb",
        "Mar",
        "Apr",
        "May",
        "June",
        "July",
        "Aug",
        "Sept",
        "Oct",
        "Nov",
        "Dec",
      ],
      selected: null,
    };
  },
  methods: {
    generateCal() {
      this.dayArr = [];
      for (let i = 0; i < this.lastDay; i++) {
        let d = new Date(this.date.getFullYear(), this.date.getMonth(), i + 1);
        this.dayArr.push(d);
      }
    },
    selectDay(item) {
      if (item >= this.today) {
        this.selected = item;
      }
    },
    findToday() {
      this.date = new Date(this.today);
    },
    next() {
      this.date.setMonth(this.date.getMonth() + 1);
      this.date = new Date(this.date);
    },
    prev() {
      if (this.date > this.today) {
        this.date.setMonth(this.date.getMonth() - 1);
        this.date = new Date(this.date);
      }
    },
    compareDates(d1, d2) {
      return d1.getTime() == d2.getTime();
    },
    setDate(val) {
      let date = new Date(val);
      let d = new Date(date.getFullYear(), date.getMonth(), date.getDate());
      this.date.setMonth(date.getMonth());
      this.date = new Date(this.date);
      this.selectDay(d);
    },
  },
  watch: {
    date() {
      this.generateCal();
    },
  },
  computed: {
    month() {
      return this.mS[this.date.getMonth()];
    },
    firstWeekday() {
      return new Date(
        this.date.getFullYear(),
        this.date.getMonth(),
        1
      ).getDay();
    },
    lastDay() {
      return new Date(
        this.date.getFullYear(),
        this.date.getMonth() + 1,
        0
      ).getDate();
    },
    currentMonth() {
      return this.mS[this.today.getMonth()];
    },
  },
  mounted() {
    this.today = new Date(
      this.today.getFullYear(),
      this.today.getMonth(),
      this.today.getDate()
    );
    this.date = new Date(this.date.getFullYear(), this.date.getMonth(), 1);
    this.selected = this.today;
    this.generateCal();
    if (this.set != null) {
      this.setDate(this.set);
    }
  },
};
</script>
<style lang="scss" scoped>
.datePicker {
  display: grid;
  width: 100%;
  max-width: 400px;
  color: var(--color-ocean);
  user-select: none;
  border: 1px solid #f1f1f1;
}
.datePicker .title {
  text-transform: uppercase;
}
.datePicker .controls {
  display: grid;
  justify-content: space-between;
  align-items: center;
  grid-auto-flow: column;
}
.datePicker .navigation {
  padding: 8px;
  border-radius: 3px;
  transition: 0.3s;
}
.datePicker .navigation:hover {
  color: black;
  background-color: lightblue;
  cursor: pointer;
}
.datePicker .navigation.inactive {
  color: hsla(0, 0%, 0%, 0.25);
  cursor: not-allowed;
  background: transparent !important;
}
.datePicker ol {
  list-style: none;
  margin: 0;
  padding: 0;
  text-align: center;
  display: grid;
  grid-template-columns: repeat(7, 1fr);
}
.datePicker ol li {
  height: 45px;
  display: flex;
  align-items: center;
  justify-content: center;
  cursor: pointer;
  transition: 0.3s;
}
.datePicker .days {
  --col-start: 1;
}
.datePicker .days li:first-child {
  grid-column-start: var(--col-start);
}
.datePicker .days li {
  outline: 2px solid transparent;
}
.datePicker .days li:not(.inactive):hover {
  outline: 2px solid var(--color-ocean);
}
.datePicker .days .selected {
  background-color: var(--color-ocean);
  outline: 2px solid #fff;
  outline-offset: -6px;
  color: #fff;
}
.datePicker .days .today {
  // background-color:#7AB2DC;
  outline: 2px solid var(--color-mist);
}
.datePicker .days .inactive {
  background-color: hsla(0, 0%, 0%, 0.25);
  color: hsla(0, 0%, 100%, 0.65);
  cursor: not-allowed;
}
.first-day {
  grid-column-start: 2;
}
</style>
