<template>
  <div class="editor" id="editorjs"></div>
</template>
<script>
import EditorJS from "@editorjs/editorjs";
import Header from "@editorjs/header";
import List from "@editorjs/list";

export default {
  name: "Editor",
  props: ["data"],
  data() {
    return {
      editor: null,
      savedData: null,
      timer: {
        load: null,
      },
    };
  },
  methods: {
    async save() {
      var x = this;
      await this.editor.isReady;
      this.editor.save().then((savedData) => {
        x.savedData = savedData;
        console.log(savedData);
      });
    },
    async load() {
      // console.log(content);
      let data = this.data || {};
      // this.editor.render();
      if (this.editor != null) {
        if (typeof this.editor.destroy === "function") {
          await this.editor.destroy();
        }
      }
      let x = this;
      this.editor = new EditorJS({
        holder: "editorjs",
        tools: {
          // header: {
          //     class: Header,
          //     inlineToolbar : true
          // },
          header: {
            class: Header,
            inlineToolbar: ["link"],
          },
          list: {
            class: List,
            inlineToolbar: true,
          },
        },
        /**
         * Previously saved data that should be rendered
         */
        data: data,
        onReady: () => {
          console.log("Editor.js is ready to work!");
          x.save();
        },
        onChange: () => {
          console.log("Now I know that Editor's content changed!");
          x.save();
        },
        placeholder: "Let`s write an awesome story!",
        /**
         * Enable autofocus
         */
        // autofocus: true
      });
    },
  },
  computed: {},
  watch: {
    data() {
      var x = this;
      clearTimeout(this.timer.load);
      this.timer.load = setTimeout(function() {
        x.load();
      }, 300);
    },
  },
  mounted() {
    var x = this;
    clearTimeout(this.timer.load);
    this.timer.load = setTimeout(function() {
      x.load();
    }, 50);
  },
};
</script>
<style lang="scss">
.editor .icon {
  color: hsla(0, 0%, 0%, 0.65);
  transition: 0.2s;
  cursor: pointer;
}
.editor .icon:hover {
  color: hsla(0, 0%, 0%, 1);
}
</style>
