<template>
    <div class="context-menu" v-show="show" :style="style" ref="context" tabindex="0" @blur="close">
        <slot></slot>
    </div>
</template>
<script>
export default {
  name: 'ContextMenu',
    props: {
        display: Boolean, // prop detect if we should show context menu
    },
  data(){
      return {
        left: 0, // left position
        top: 0, // top position
        show: true, // affect display of context menu
      }
  },
  methods: {
      // closes context menu 
        close() {
            this.show = false;
            this.left = 0;
            this.top = 0;
        },
        open(evt) {
            // updates position of context menu 
            this.left = evt.pageX || evt.clientX;
            this.top = evt.pageY || evt.clientY;
            // make element focused 
            // @ts-ignore
            // this.nextTick(() => this.$el.focus());
            this.show = true;
        },
  },
  computed: {
        // get position of context menu
        style() {
            return {
                top: this.top + 'px',
                left: this.left + 'px',
            };
        },
    },
}
</script>
<style>
.context-menu {
    position: fixed;
    background: #fff;
    min-height:20px;
    width:150px;
    z-index: 999;
    outline: none;
    box-shadow: 0 1px 8px rgba(0, 0, 0, 0.12), 0 1px 2px rgba(0, 0, 0, 0.24);
    cursor: pointer;
    transform: translateX(-100%);
    border-radius:3px;
    padding:8px 0;
}
.context-menu .material-icons{
    font-size:20px;
}
.context-menu .option{
    padding:8px 16px;
    display:grid;
    grid-auto-flow: column;
    grid-gap:8px;
    align-items: center;
    justify-content: flex-start;
    user-select: none;
}
.context-menu .option:hover{
    background-color:#0066ff10;
    color:#0068ff;
}
</style>