<template>
  <teleport to="#module">
    <Module ref="module" class="module">
      <div el="list-column-between">
        <h3 el>Delete Conversation</h3>
        <span class="material-icons" @click="$refs.module.toggle()" el="icon"
          >close</span
        >
      </div>
      <div el="list md">
        <p el>Are you sure you want to delete this conversation?</p>
        <div el="list-column-between">
          <div></div>
          <div>
            <div el="btn" @click="deleteUser(selectedUser.id)">Delete</div>
          </div>
        </div>
      </div>
    </Module>
  </teleport>
  <div el="list" style="--gap:16px;max-width:1200px;">
    <div el="tabs-underline">
      <div
        :class="{ active: users.filters.approvedAccount }"
        @click="users.filters.approvedAccount = true"
      >
        Approved Users
      </div>
    </div>
    <div :class="{ 'dynamic-table1': true, selected: selectedUser != null }">
      <div style="padding:20px;">
        <!-- <div el="indeterminate-progress"></div> -->
        <div el="input-list">
          <label el="input" class="search">
            <input
              v-model="users.search"
              type="text"
              name="input"
              placeholder="Search..."
              el
            />
            <span class="material-icons">search</span>
          </label>
        </div>
      </div>
      <div v-if="selectedUser == null"></div>
      <div
        v-else
        style="grid-row: span 2;display: grid;grid-template-rows: max-content 1fr;overflow:hidden;"
      >
        <div
          el="list"
          style="--gap:8px;padding-top:24px;--border-bottom: 1px solid #ccc;--hover-border-bottom:var(--border-bottom);--pressed-border-bottom:var(--border-bottom);"
        >
          <!-- <div el="indeterminate-progress"></div> -->
          <div
            el="list-column-between"
            style="padding:0 24px;"
            class="v-center"
          >
            <div el="list-column" class="v-center">
              <h4 el>
                {{ selectedUser.firstName }} {{ selectedUser.lastName }}
              </h4>
              <!-- <span class="material-icons" el="icon-2ry">edit</span> -->
            </div>
            <!-- <div el="list-column" class="v-center" style="--gap: 16px;">
              
            </div> -->
          </div>
          <div
            el="list-column-between"
            style="padding:0 24px;padding-bottom:16px;"
            class="v-center"
          >
            <div el="list-column" class="v-center">
              <p el>Administrator</p>
            </div>
            <div el="list-column" class="v-center" style="--gap: 16px;">
              <div el="flat-btn-2ry" @click="$refs.module.toggle()">
                <span class="material-icons">delete</span>
                <span>Delete</span>
              </div>
              <!-- <div el="flat-btn-2ry">
                                <span class="material-icons">file_copy</span>
                                <span>Duplicate</span>
                            </div> -->
              <!-- <span class="material-icons" el="icon-2ry">view_module</span>
                            <span class="material-icons" el="icon-2ry">view_list</span> -->
            </div>
          </div>
        </div>
        <div style="overflow: auto;">
          <!-- User Details -->
          <div el="list sm" style="padding:24px;">



          <div class="messages">
              <div class="chat">
                <div class="user-msg">
                  <div class="msg">test</div>
                </div>
                <div>
                  <small><i>Wesley Burke</i></small>
                  <div class="msg">test</div>
                </div>
              </div>
            <div class="actions">
              <input type="text">
              <div class="send">
                <span class="material-icons">send</span>
              </div>
            </div>
          </div>












          </div>
        </div>
      </div>
      <div style="overflow: auto;">
        <table el="table">
          <colgroup>
            <col style="width:150px;" />
            <col />
          </colgroup>
          <thead>
            <th>Name</th>
            <th>Role</th>
          </thead>
          <tbody>
            <tr
              v-for="item in filteredUsers"
              :key="item"
              @click="selectUser(item.email)"
              :class="{ active: users.selected == item.email }"
            >
              <td>{{ item.firstName }} {{ item.lastName }}</td>
              <td v-if="item.permissions.includes('admin')">Admin</td>
              <td v-else>Resident</td>
            </tr>
          </tbody>
        </table>
      </div>
    </div>
  </div>
</template>
<script>
import _ from "lodash";
import { db } from "../firebase";
import Module from "@/components/Module.vue";

export default {
  name: "UserManagement",
  props: ["title"],
  components: {
    Module,
  },
  data() {
    return {
      users: {
        filters: {
          approvedAccount: true,
        },
        selected: null,
        search: "",
        //   filters:{

        //   },
        list: [
          {
            firstName: "First",
            lastName: "Last",
            email: "name@email.com",
            address: "address",
            phone: "phone",
            approvedAccount: false,
            permissions: [],
            role: "",
            createdOn: Date.now(),
          },
        ],
      },
    };
  },
  methods: {
    selectUser(email) {
      this.users.selected = email;
    },
    deselectUser() {
      this.users.selected = null;
    },
    // firebase methods
    getUserData() {
      var x = this;
      var users = db.collection("users"),
        query = users.where(
          "approvedAccount",
          "==",
          x.users.filters.approvedAccount
        );
      x.users.list = [];
      query
        .get()
        .then((querySnapshot) => {
          querySnapshot.forEach((doc) => {
            // doc.data() is never undefined for query doc snapshots
            var data = doc.data();
            data.id = doc.id;
            x.users.list.push(data);
            //console.log(doc.id, " => ", doc.data());
          });
        })
        .catch((error) => {
          console.log("Error getting documents: ", error);
        });
      // console.log(query);
    },
    setAccess(id, val) {
      var x = this;
      db.collection("users")
        .doc(id)
        .update({
          approvedAccount: val,
        })
        .then(() => {
          console.log("Document successfully updated!");
          x.getUserData();
        });
    },
    deleteUser(uid) {
      var x = this;
      db.collection("users")
        .doc(uid)
        .delete()
        .then(() => {
          x.$refs.module.toggle();
          x.getUserData();
        })
        .catch((error) => {
          alert(error);
          console.error("Error removing document: ", error);
        });
    },
    formatDate(date){
        let d = new Date(date);
        return d.toLocaleString('en-US');
    },
  },
  watch: {
    "users.filters.approvedAccount"() {
      this.getUserData();
    },
  },
  computed: {
    selectedUser() {
      if (this.users.selected != null) {
        return _.find(this.users.list, { email: this.users.selected });
        //   return _.filter(this.users.list, { 'id': this.users.selected });
      } else {
        return null;
      }
    },
    filteredUsers() {
      var x = this;
      return _.filter(this.users.list, function(item) {
        return _.includes(
          _.lowerCase(item.firstName + " " + item.lastName + " " + item.email),
          x.users.search
        );
      });
    },
  },
  mounted() {
    this.getUserData();
  },
};
</script>
<style lang="scss" scoped>
.material-icons {
  font-family: "Material Icons";
  font-weight: normal;
  font-style: normal;
  font-size: 24px;
  line-height: 1;
  letter-spacing: normal;
  text-transform: none;
  display: inline-block;
  white-space: nowrap;
  word-wrap: normal;
  direction: ltr;
  -webkit-font-feature-settings: "liga";
  -webkit-font-smoothing: antialiased;
}
[el="tabs"] {
  user-select: none;
}
[el="table"],
[el|="table"] {
  --control-border: 0px;
}
[el="table"] tbody tr {
  height: 46.4px;
  /* content-visibility:auto;
    contain-intrinsic-size:1px 46.4px; */
}
[el="table"] tr:hover,
[el|="table"] tr:hover,
[el="table"] tr.active {
  /* box-shadow: 0 0 0 1px var(--color-sky); */
  outline: 1px solid var(--color-sky);
  outline-offset: -1px;
}
[el="table"] tr.active {
  background-color: var(--color-sky-5);
}
td {
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
}
thead th {
  background: white;
  position: sticky;
  top: 0;
  z-index: 10;
  box-shadow: 0 1px 0 var(--color-25);
}
.table-action thead th {
  box-shadow: 0 1px 0 var(--color-25), 0 1px 0 var(--color-25) inset;
}
.dynamic-table1 {
  display: grid;
  background: #ccc;
  height: 550px;
  display: grid;
  grid-template-columns: 1fr 0px;
  grid-template-rows: max-content 1fr;
  grid-gap: 1px;
  overflow: hidden;
    box-shadow: 0 3px 10px rgba(0,0,0,0.2);
    border-radius: 5px;
}
.dynamic-table1.selected {
  grid-template-columns: 1fr 750px;
}
.dynamic-table1 > * {
  background: #fff;
  position: relative;
}
.search .material-icons {
  position: absolute;
  right: 0;
  top: 50%;
  transform: translateY(-50%);
  color: var(--color-40);
  transition: 0.2s;
  -webkit-text-fill-color: currentColor;
}
.search:hover .material-icons,
.search:focus .material-icons {
  color: var(--color-sky);
}
.close {
  color: var(--color-40);
  -webkit-text-fill-color: currentColor;
  cursor: pointer;
  transition: 0.2s;
}
.close:hover {
  color: var(--color-sky);
}
.v-center {
  align-items: center;
}
tr.bold > td {
  font-weight: var(--font-medium);
}
.table-action th:first-child,
.table-action tr td:first-child {
  padding-left: 54px;
}
.table-action tr td:first-child {
  position: relative;
}
.table-action th:first-child .material-icons,
.table-action tr td:first-child .material-icons {
  position: absolute;
  top: 50%;
  transform: translateY(-50%);
  left: 14px;
}

.thumbnail {
  width: 100%;
  height: 90px;
  object-fit: contain;
  opacity: 0.07;
  object-position: center;
}
.status {
  --size: 20px;
  height: var(--size);
  width: var(--size);
  display: inline-block;
  position: absolute;
  transform: translateY(-50%) translateX(-100%);
}
.text-right {
  text-align: right;
}
.ce-header{
  font-weight:bold;
  font-size:1.5rem;
}











.messages {
    display: grid;
    grid-template-rows: 1fr max-content;
    height: 394px;
    grid-gap: 16px;
    /*   border:1px solid #ccc; */
    overflow: hidden;
    border-radius: 8px;

}


.header h2 {
    margin: 0;
}

.chat {
    overflow: auto;
    display: grid;
    grid-gap: 16px;
    align-content: flex-end;
}

.chat>* {
    display: grid;
    justify-content: start;
}

.chat>.user-msg {
    justify-content: end;
}

.chat .msg {
    padding: 8px 10px;
    border-radius: 10px;
    background-color: #f1f1f1;
}

.user-msg .msg {
    background-color: #2facee;
    color: #fff;
}

.actions {
    display: grid;
    grid-auto-flow: column;
    grid-gap: 4px;
    height: 45px;
    grid-template-columns: 1fr max-content;
}

.actions input {
    border: 0;
    border-radius: 100px;
    background-color: #f1f1f1;
    padding:8px 16px;
}

.actions .send {
    height: 45px;
    width: 45px;
    background-color: #2facee;
    display: grid;
    align-items: center;
    justify-content: center;
    border-radius: 100px;
    color: #fff;
    transition: 0.2s;
    cursor: pointer;
    user-select: none;
}

.actions .send:hover {
    background-color: darkblue;
}

</style>
