<template>
    <div el="list sm" class="post-content" v-if="data != null">
        <div v-for="item in data.blocks" :key="item">
            <p el v-if="item.type == 'paragraph'" v-html="item.data.text"></p>
            <h2 el v-else-if="item.type == 'header' && item.data.level == 2" v-html="item.data.text"></h2>
            <ul el v-else-if="item.type == 'list' && item.data.style == 'unordered'">
                <li v-for="li in item.data.items" v-html="li" :key="li"></li>
            </ul>
            <ol el v-else-if="item.type == 'list' && item.data.style == 'ordered'">
                <li v-for="li in item.data.items" v-html="li" :key="li"></li>
            </ol>
        </div>
    </div>
</template>
<script>

export default {
    name: 'ViewPost',
    props:['data'],
    data(){
        return {}
    },
    methods: {
        // load(content){
        //     console.log(content);
        //     let data = content || {};
        //     this.data = content;
        // }
    },
    computed: {
            
    },
    mounted(){
        
    }
}
</script>
<style lang="scss">

</style>