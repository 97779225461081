<template>
    <div el="accordion" :class="{active: active}">
        <div el="list-column" @click="toggle">
            <span el="icon-active" class="material-icons"></span> 
            <p el="color-sea">{{title}}</p>
        </div>
        <slot></slot>
    </div>
</template>
<script>
import {ref} from 'vue'
// import {ref, toRefs} from 'vue'

export default {
    props:['title'],
    setup (){
        let active = ref(false)
        
        function toggle(){
            active.value = !active.value
        }

        return {
            active,
            toggle
        }
    }
}
</script>