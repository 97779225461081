<template>
  <teleport to="#module">
    <Module ref="module" class="module">
      <div el="list-column-between">
        <h3 el>Delete Post</h3>
        <span class="material-icons" @click="$refs.module.toggle()" el="icon"
          >close</span
        >
      </div>
      <div el="list md">
        <p el>Are you sure you want to delete this post?</p>
        <div el="list-column-between">
          <div></div>
          <div>
            <div el="btn" @click="deletePost(selectedPost.id)">Delete Post</div>
          </div>
        </div>
      </div>
    </Module>
  </teleport>
  <div el="list" style="--gap:16px;max-width:1200px;">
    <div el="list-column-between">
      <div el="tabs-underline">
        <div
          :class="{ active: posts.section == 'draft' }"
          @click="applyFilter('draft')"
        >
          DRAFTS
        </div>
        <div
          :class="{ active: posts.section == 'residents' }"
          @click="applyFilter('residents')"
        >
          RESIDENTS
        </div>
        <div
          :class="{ active: posts.section == 'public' }"
          @click="applyFilter('public')"
        >
          PUBLIC
        </div>
        <div
          :class="{ active: posts.section == 'card' }"
          @click="applyFilter('card')"
        >
          CARDS
        </div>
      </div>
      <div
        el="btn shadow-sm"
        @click="createPost"
        v-if="posts.section == 'draft'"
      >
        Create Post
      </div>
    </div>
    <div :class="{ 'dynamic-table1': true, selected: selectedPost != null }">
      <div style="padding:20px;">
        <!-- <div el="indeterminate-progress"></div> -->
        <div el="input-list">
          <label el="input" class="search">
            <input
              v-model="posts.search"
              type="text"
              name="input"
              placeholder="Search..."
              el
            />
            <span class="material-icons">search</span>
          </label>
        </div>
      </div>
      <div v-if="selectedPost == null"></div>
      <div
        v-else
        style="grid-row: span 2;display: grid;grid-template-rows: max-content 1fr;overflow:hidden;"
      >
        <div
          el="list"
          style="--gap:8px;padding-top:24px;--border-bottom: 1px solid #ccc;--hover-border-bottom:var(--border-bottom);--pressed-border-bottom:var(--border-bottom);"
        >
          <!-- <div el="indeterminate-progress"></div> -->
          <div
            el="list-column-between"
            style="padding:0 24px;"
            class="v-center"
          >
            <div el="list-column" class="v-center">
              <h4 el v-if="posts.SelectedPostState != 'edit'">
                {{ selectedPost.title }}
              </h4>
              <label el="input" v-else>
                <input
                  type="text"
                  class="input-title"
                  name="input"
                  placeholder="Post Title"
                  v-model.trim="selectedPost.title"
                  el
                />
              </label>
              <!-- <span class="material-icons" el="icon-2ry">edit</span> -->
            </div>
            <div el="list-column" class="v-center" style="--gap: 16px;">
              <div
                el="btn-2ry"
                v-if="posts.SelectedPostState != 'edit'"
                @click="posts.SelectedPostState = 'edit'"
              >
                Edit Post
              </div>
              <div
                el="btn-2ry"
                v-if="posts.SelectedPostState == 'edit'"
                @click="updatePost"
              >
                Save
              </div>
              <div
                el="btn"
                v-if="selectedPost.status == 'draft'"
                @click="updatePostStatus('published')"
              >
                Publish
              </div>
              <div
                el="btn"
                v-if="selectedPost.status == 'published'"
                @click="updatePostStatus('draft')"
              >
                Make Draft
              </div>
              <!-- <span class="material-icons" el="icon-2ry" @click="deselect">close</span> -->
            </div>
          </div>
          <div
            el="list-column-between"
            style="padding:0 24px;padding-bottom:16px;"
            class="v-center"
          >
            <div el="list-column" class="v-center">
              <!-- <p el>{{selectedPost.summary}}</p> -->
              <!-- <div el="flat-btn-2ry" style="padding: 0;">
                                <span class="material-icons">radio_button_unchecked</span>
                                <span>Select All</span>
                            </div> -->
            </div>
            <div el="list-column" class="v-center" style="--gap: 16px;">
              <div el="flat-btn-2ry" @click="$refs.module.toggle()">
                <span class="material-icons">delete</span>
                <span>Delete</span>
              </div>
              <!-- <div el="flat-btn-2ry">
                                <span class="material-icons">file_copy</span>
                                <span>Duplicate</span>
                            </div> -->
              <!-- <span class="material-icons" el="icon-2ry">view_module</span>
                            <span class="material-icons" el="icon-2ry">view_list</span> -->
            </div>
          </div>
        </div>
        <div style="overflow: auto;">
          <!-- User Details -->
          <div el="list sm" style="padding:24px;">
            <!-- Use accordian component instead -->
            <Accordion title="Details">
              <div el="list xxsm" style="padding-left:30px;">
                <p el><b>Author:</b> {{ selectedPost.author.name }}</p>
                <p el>
                  <b>Created On:</b> {{ formatDate(selectedPost.createdOn) }}
                </p>
                <p el>
                  <b>Last Update:</b> {{ formatDate(selectedPost.updatedOn) }}
                </p>
                <p el><b>Post ID:</b> {{ selectedPost.id }}</p>
              </div>
            </Accordion>
            <div el="list-column-alt xsm" style="align-items:center;">
              <p el="bold">Tags</p>
              <div
                el="toggle"
                :class="{ active: selectedPost.tags.includes('public') }"
                @click="toggleTag('public')"
              >
                Public
              </div>
              <div
                el="toggle"
                :class="{ active: selectedPost.tags.includes('residents') }"
                @click="toggleTag('residents')"
              >
                Residents
              </div>
              <div
                el="toggle"
                :class="{ active: selectedPost.tags.includes('card') }"
                @click="toggleTag('card')"
              >
                card
              </div>
            </div>
            <div
              el="list xsm"
              style="align-items:center;"
              v-if="selectedPost.img != '' || posts.SelectedPostState == 'edit'"
            >
              <p el="bold">Background Image</p>
              <!-- <p el v-if="posts.SelectedPostState != 'edit'">
                {{ selectedPost.img }}
              </p> -->
              <img
                v-if="posts.SelectedPostState != 'edit'"
                :src="selectedPost.img"
                style="height:100px;"
                loading="lazy"
              />
              <label el="input" v-else>
                <input
                  type="text"
                  class="input-text"
                  name="input"
                  placeholder="Image URL: https://example.com"
                  v-model.trim="selectedPost.img"
                  el
                />
              </label>
            </div>
            <hr el />
            <p el="bold">Content</p>
            <ViewPost
              ref="viewPost"
              v-if="posts.SelectedPostState != 'edit'"
              :data="selectedPost.content"
            />
            <Editor
              ref="editor"
              v-if="posts.SelectedPostState == 'edit'"
              :data="selectedPost.content"
            />
            <!-- <div el="list xxsm">
                            <div el="list-column">
                                
                            </div>
                        </div> -->
          </div>
        </div>
      </div>
      <div style="overflow: auto;">
        <table el="table">
          <colgroup>
            <col style="width:150px;" />
            <col />
          </colgroup>
          <thead>
            <th>Title</th>
            <th>Created On</th>
          </thead>
          <tbody>
            <tr
              v-for="item in filteredPosts"
              :key="item"
              @click="select(item.id)"
              :class="{ active: posts.selected == item.id }"
            >
              <td>{{ item.title }}</td>
              <td>{{ formatDate(item.createdOn) }}</td>
            </tr>
          </tbody>
        </table>
      </div>
    </div>
  </div>
</template>
<script>
import _ from "lodash";
import { auth, db } from "../firebase";
import Module from "@/components/Module.vue";
import Editor from "@/components/Editor.vue";
import ViewPost from "@/components/ViewPost.vue";
import Accordion from "@/components/Accordion.vue";

export default {
  name: "UserManagement",
  props: ["title"],
  components: {
    Module,
    Editor,
    ViewPost,
    Accordion,
  },
  data() {
    return {
      module: {
        section: "",
      },
      posts: {
        filters: {
          status: "draft",
        },
        section: "draft",
        selected: null,
        SelectedPostState: "",
        search: "",
        list: [
          {
            id: "1234",
            title: "Post Title",
            summary: "Brief summary here...",
            img: "",
            author: "Author User ID",
            status: "draft",
            tags: ["residents", "public"],
            createdOn: Date.now(),
            updatedOn: Date.now(),
          },
        ],
      },
    };
  },
  methods: {
    select(id) {
      this.posts.selected = id;
      this.posts.SelectedPostState = "";
      this.loadPostContent();
    },
    deselect() {
      this.posts.selected = null;
    },
    applyFilter(section) {
      switch (section) {
        case "draft":
          this.posts.filters.status = "draft";
          break;
        default:
          this.posts.filters.status = "published";
      }
      this.posts.section = section;
      this.posts.selected = null;
    },
    // firebase methods
    getPostsData() {
      var x = this;
      var posts = db.collection("posts");
      x.posts.list.splice(0, x.posts.list.length);
      posts
        .get()
        .then((querySnapshot) => {
          querySnapshot.forEach((doc) => {
            var data = doc.data();
            data.id = doc.id;
            x.posts.list.push(data);
          });
        })
        .catch((error) => {
          console.log("Error getting documents: ", error);
        });
    },
    toggleTag(tagName) {
      var x = this;
      let tags = x.selectedPost.tags;
      let i = tags.indexOf(tagName);
      if (i >= 0) {
        tags.splice(i, 1);
      } else {
        tags.push(tagName);
      }
      db.collection("posts")
        .doc(x.selectedPost.id)
        .update({
          tags: tags,
        })
        .then(() => {
          console.log("Successfully updated tags!");
          // x.getPostsData();
        });
    },
    createPost() {
      var x = this;
      let data = {
        title: "Title",
        summary: "Brief summary here...",
        img: "",
        author: {
          uid: auth.currentUser.uid,
          email: auth.currentUser.email,
          name: x.userProfile.firstName + " " + x.userProfile.lastName,
        },
        status: "draft",
        tags: ["residents", "public"],
        createdOn: Date.now(),
        updatedOn: Date.now(),
      };
      db.collection("posts")
        .add(data)
        .then((doc) => {
          //   x.getPostsData();
          //switch to new post
          data.id = doc.id;
          x.posts.list.push(data);
          x.select(doc.id);
        })
        .catch((error) => {
          alert(error);
          console.error("Error removing document: ", error);
        });
    },
    updatePost() {
      var x = this;
      let d = Date.now();
      db.collection("posts")
        .doc(this.selectedPost.id)
        .update({
          title: x.selectedPost.title,
          img: x.selectedPost.img,
          content: x.$refs.editor.savedData,
          updatedOn: d,
        })
        .then(() => {
          console.log("Document successfully updated!");
          x.selectedPost.content = x.$refs.editor.savedData;
          x.selectedPost.updatedOn = d;
          // x.getPostsData();
        });
    },
    updatePostStatus(status) {
      var x = this;
      // Save and Publish
      let d = Date.now();
      if (x.$refs.editor) {
        db.collection("posts")
          .doc(this.selectedPost.id)
          .update({
            title: x.selectedPost.title,
            img: x.selectedPost.img,
            status: status,
            content: x.$refs.editor.savedData,
            updatedOn: d,
          })
          .then(() => {
            console.log("Post is published!");
            x.selectedPost.content = x.$refs.editor.savedData;
            x.selectedPost.updatedOn = d;
            x.posts.selected = null;
            x.getPostsData();
          });
      } else {
        db.collection("posts")
          .doc(this.selectedPost.id)
          .update({
            title: x.selectedPost.title,
            img: x.selectedPost.img,
            status: status,
            updatedOn: d,
          })
          .then(() => {
            console.log("Post is published!");
            x.selectedPost.updatedOn = d;
            this.posts.selected = null;
            x.getPostsData();
          });
      }
    },
    loadPostContent() {
      var x = this;
      console.log("Content: ", x.selectedPost.content);
      if (x.posts.SelectedPostState == "edit") {
        setTimeout(function() {
          x.$refs.editor.load(x.selectedPost.content);
        }, 100);
      } else {
        //   setTimeout(function(){
        //     x.$refs.ViewPost.load(x.selectedPost.content);
        // }, 100);
      }
    },
    deletePost(postID) {
      var x = this;
      db.collection("posts")
        .doc(postID)
        .delete()
        .then(() => {
          x.$refs.module.toggle();
          x.getPostsData();
        })
        .catch((error) => {
          alert(error);
          console.error("Error removing document: ", error);
        });
    },
    getEditorData() {
      console.log(this.$refs.editor.savedData);
    },
    formatDate(date) {
      let d = new Date(date);
      return d.toLocaleString("en-US");
    },
  },
  watch: {},
  computed: {
    userProfile() {
      return this.$store.state.userProfile;
    },
    selectedPost() {
      if (this.posts.selected != null) {
        return _.find(this.posts.list, { id: this.posts.selected });
        //   return _.filter(this.posts.list, { 'id': this.posts.selected });
      } else {
        return null;
      }
    },
    filteredPosts() {
      var x = this;
      let posts = _.orderBy(x.posts.list, ["createdOn"], ["desc"]);
      if (x.posts.section == "draft") {
        return _.filter(posts, function(item) {
          return (
            _.includes(
              _.lowerCase(item.title + " " + item.summary),
              x.posts.search
            ) && item.status == x.posts.filters.status
          );
        });
      } else {
        return _.filter(posts, function(item) {
          return (
            _.includes(
              _.lowerCase(item.title + " " + item.summary),
              x.posts.search
            ) &&
            item.status == x.posts.filters.status &&
            _.includes(item.tags, x.posts.section)
          );
        });
      }
    },
  },
  mounted() {
    this.getPostsData();
    console.log(auth.currentUser);
  },
};
</script>
<style lang="scss">
.input-title,
.input-text {
  font-size: var(--font-h4);
  font-weight: var(--font-medium);
  --bg-color: rgba(0, 0, 0, 0.05);
  padding: 4px;
  border-radius: 3px;
}
.input-text {
  font-size: var(--font-p);
  font-weight: var(--font-regular);
}
.material-icons {
  font-family: "Material Icons";
  font-weight: normal;
  font-style: normal;
  font-size: 24px;
  line-height: 1;
  letter-spacing: normal;
  text-transform: none;
  display: inline-block;
  white-space: nowrap;
  word-wrap: normal;
  direction: ltr;
  -webkit-font-feature-settings: "liga";
  -webkit-font-smoothing: antialiased;
}
[el="tabs"] {
  user-select: none;
}
[el="table"],
[el|="table"] {
  --control-border: 0px;
}
[el="table"] tbody tr {
  height: 46.4px;
  /* content-visibility:auto;
    contain-intrinsic-size:1px 46.4px; */
}
[el="table"] tr:hover,
[el|="table"] tr:hover,
[el="table"] tr.active {
  /* box-shadow: 0 0 0 1px var(--color-sky); */
  outline: 1px solid var(--color-sky);
  outline-offset: -1px;
}
[el="table"] tr.active {
  background-color: var(--color-sky-5);
}
td {
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
}
thead th {
  background: white;
  position: sticky;
  top: 0;
  z-index: 10;
  box-shadow: 0 1px 0 var(--color-25);
}
.table-action thead th {
  box-shadow: 0 1px 0 var(--color-25), 0 1px 0 var(--color-25) inset;
}
.dynamic-table1 {
  display: grid;
  background: #ccc;
  height: 550px;
  display: grid;
  grid-template-columns: 1fr 0px;
  grid-template-rows: max-content 1fr;
  grid-gap: 1px;
  overflow: hidden;
  box-shadow: 0 3px 10px rgba(0, 0, 0, 0.2);
  border-radius: 5px;
}
.dynamic-table1.selected {
  grid-template-columns: 1fr 750px;
}
.dynamic-table1 > * {
  background: #fff;
  position: relative;
}
.search .material-icons {
  position: absolute;
  right: 0;
  top: 50%;
  transform: translateY(-50%);
  color: var(--color-40);
  transition: 0.2s;
  -webkit-text-fill-color: currentColor;
}
.search:hover .material-icons,
.search:focus .material-icons {
  color: var(--color-sky);
}
.close {
  color: var(--color-40);
  -webkit-text-fill-color: currentColor;
  cursor: pointer;
  transition: 0.2s;
}
.close:hover {
  color: var(--color-sky);
}
.v-center {
  align-items: center;
}
tr.bold > td {
  font-weight: var(--font-medium);
}
.table-action th:first-child,
.table-action tr td:first-child {
  padding-left: 54px;
}
.table-action tr td:first-child {
  position: relative;
}
.table-action th:first-child .material-icons,
.table-action tr td:first-child .material-icons {
  position: absolute;
  top: 50%;
  transform: translateY(-50%);
  left: 14px;
}

.thumbnail {
  width: 100%;
  height: 90px;
  object-fit: contain;
  opacity: 0.07;
  object-position: center;
}
.status {
  --size: 20px;
  height: var(--size);
  width: var(--size);
  display: inline-block;
  position: absolute;
  transform: translateY(-50%) translateX(-100%);
}
.text-right {
  text-align: right;
}
[el*="table"] td {
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
  max-width: 250px;
}
</style>
